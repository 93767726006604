import React, { Fragment } from 'react';
import { formatCurrency } from '../../../lib/currency-utils';

const LeaderBoardMobileCard = ({ order, collective, small }) => {
  return (
    <Fragment>
      <style jsx>{`
        .leaderboard-card-mb {
          width: 104px;
          min-width: 104px;
          padding: 8px 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #fff;
          background: #004cbe;
          border-radius: 16px;

          position: relative;
          min-height: 152px;
        }

        .leaderboard-card-mb span {
          color: #333;
        }

        .leaderboard-card-mb:nth-child(2) {
          margin: 0 16px;
        }

        .leaderboard-card-mb:nth-child(1),
        .leaderboard-card-mb:nth-child(3) {
          position: relative;
          top: 36px;
        }

        .leaderboard-card-mb__logo-box {
          background-color: #fff;
          border: 4px solid #004cbe;
          border-radius: 100px;
          width: 58px;
          height: 58px;

          display: flex;
          justify-content: center;
          align-items: center;

          position: absolute;
          top: -28px;
        }

        .leaderboard-card-mb__logo-wraper {
          width: 32px;
          min-width: 32px;
          height: 40px;
        }

        .leaderboard-card-mb__logo {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .leaderboard-card-mb__organization-name {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          margin: 0;
          margin-top: 25px;
          text-align: center;
        }

        .leaderboard-card-mb__slogan {
          margin: 0;
          font-size: 10px;
          line-height: 16px;
          padding: 0 4px;
          text-align: center;
          margin-top: 2px;
          margin-bottom: 10px;
        }

        .leaderboard-card-mb__divider {
          border: 1px solid #2878f0;
          width: calc(100% + 8px);
          margin-top: auto;
        }

        .leaderboard-card-mb__donate-value {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          margin: 4px 0px;
          text-align: center;
        }

        .leaderboard-card-mb--small {
          width: 94px;
          min-width: 94px;
          background: #f47421;
          min-height: 132px;
        }

        .leaderboard-card-mb--small .leaderboard-card-mb__divider {
          border-color: #fff;
        }

        .leaderboard-card-mb--small .leaderboard-card-mb__logo-box {
          border-color: #f47421;
          width: 48px;
          height: 48px;
        }

        .leaderboard-card-mb--small .leaderboard-card-mb__logo-wraper {
          border-color: #f47421;
          width: 26px;
          min-width: 26px;
          height: 32.5px;
        }

        .leaderboard-card-mb--small .leaderboard-card-mb__organization-name {
          margin-top: 15px;
        }
      `}</style>

      <a
        className={`leaderboard-card-mb ${small && 'leaderboard-card-mb--small'}`}
        href={collective?.slug}
        target="_blank"
      >
        <div className="leaderboard-card-mb__logo-box">
          <div className="leaderboard-card-mb__logo-wraper">
            <img src={`/static/images/leaderboard/medal--${order}.png`} className="leaderboard-card-mb__logo" />
          </div>
        </div>

        <p className="leaderboard-card-mb__organization-name">{collective?.name}</p>
        {/* <p className="leaderboard-card-mb__slogan"> {collective?.host?.name}</p> */}

        <div className="leaderboard-card-mb__divider"></div>

        <p className="leaderboard-card-mb__donate-value"> {formatCurrency(collective?.totalDonationReceived || 0)}</p>
      </a>
    </Fragment>
  );
};

export default LeaderBoardMobileCard;

import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';

export const END_TIME = '2022-12-31T18:00:00.000-04:00';
const DAYS = 'Days';
const HOURS = 'Hours';
const MINUTES = 'Minutes';
const SECONDS = 'Seconds';

const getDescription = (num, plural) => {
  return num > 1 ? plural : plural.slice(0, plural.length - 1);
};

const CountdownCard = ({ number, pluralDescription, onModal }) => {
  return (
    <Fragment>
      <style jsx>
        {`
          .leaderboard-countdown-card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 12px;
            gap: 6px;
            width: 68px;
            height: 68px;
            background: #ffffff;
            border: 1px solid #a4c8ff;
            border-radius: 10px;
            color: #cb8700;
          }

          .leaderboard-countdown-card__number {
            margin: 0;
            font-weight: 600;
            font-size: 28px;
            line-height: 24px;
          }

          .leaderboard-countdown-card__description {
            margin: 0;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
          }

          @media (max-width: 600px) {
            .leaderboard-countdown-card {
              width: 60px;
              height: 50px;
              color: #ffffff;
              background: transparent;
            }

            .leaderboard-countdown-card__number {
              font-size: 16px;
              line-height: 16px;
            }

            .leaderboard-countdown-card__description {
              font-size: 12px;
              line-height: 14px;
            }
          }

          .leaderboard-countdown-card--on-modal {
            border: none;
            color: #f47421;
          }

          .leaderboard-countdown-card--on-modal.leaderboard-countdown-card {
            background: #fff;
            width: 68px;
            height: 68px;
          }
        `}
      </style>
      <div className={`leaderboard-countdown-card ${onModal && 'leaderboard-countdown-card--on-modal'}`}>
        <p className="leaderboard-countdown-card__number">{number}</p>
        <p className="leaderboard-countdown-card__description">{getDescription(number, pluralDescription)}</p>
      </div>
    </Fragment>
  );
};

const getCountdown = endTime => {
  const duration = moment.duration(moment(endTime) - moment());
  const timeleft = duration.asMilliseconds();
  const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

  return {
    days: days < 0 ? 0 : days,
    hours: hours < 0 ? 0 : hours,
    minutes: minutes < 0 ? 0 : minutes,
    seconds: seconds < 0 ? 0 : seconds,
  };
};

export const Countdown = ({ endTime, title, onModal }) => {
  const [{ days, hours, minutes, seconds }, setCountdown] = useState(() => getCountdown(endTime));

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(getCountdown(endTime));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Fragment>
      <style jsx>{`
        .leaderboard-countdown__title {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          text-transform: uppercase;
          color: #ffffff;
          margin-bottom: 14px;
        }

        .leaderboard-countdown__main {
          display: flex;
          gap: 12px;
        }

        .leaderboard-countdown--on-modal .leaderboard-countdown__title {
          text-align: center;
          color: #f47421;
        }
      `}</style>
      <div className={`leaderboard-countdown ${onModal && 'leaderboard-countdown--on-modal'}`}>
        <p className="leaderboard-countdown__title">{title} </p>
        <div className="leaderboard-countdown__main">
          <CountdownCard number={days} pluralDescription={DAYS} onModal />
          <CountdownCard number={hours} pluralDescription={HOURS} onModal />
          <CountdownCard number={minutes} pluralDescription={MINUTES} onModal />
          <CountdownCard number={seconds} pluralDescription={SECONDS} onModal />
        </div>
      </div>
    </Fragment>
  );
};

const Header = ({ contest }) => {
  return (
    <Fragment>
      <style jsx>
        {`
          .leaderboard-header {
            margin-bottom: 32px;
          }

          .leaderboard-header__link {
            color: #f29962;
            text-decoration: underline;
          }

          .leaderboard-main__logo {
            width: 52px;
            height: auto;
            margin-right: 10px;
            margin-bottom: 5px;
            align-self: baseline;
          }

          .leaderboard-main__header {
            display: flex;
            align-item: center;
            margin-bottom: 20px;
          }

          .leaderboard-main__title {
            margin: 0;
            line-height: 52px;
            margin-left: 15px;
            font-family: Roboto;
            font-weight: 500;
            font-size: 38px;
            color: #fff;
          }

          .leaderboard-main__sub-title {
            color: #fff;
            font-size: 16px;
            margin-bottom: 32px;
            font-weight: 400;
          }

          .leaderboard-header__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
          }

          .leaderboard-header__title {
            margin: 0;
            max-width: 652px;
            line-height: 47px;
            font-family: Roboto;
            font-weight: 700;
            font-size: 40px;
            color: #fff;
            text-align: start;
            display: flex;
          }

          .leaderboard-header__sub-title {
            color: #fff;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
          }

          @media (max-width: 982px) {
            .leaderboard-header {
              margin-bottom: 40px;
            }

            .leaderboard-header__title {
              font-size: 24px;
              line-height: 32px;
            }
          }

          @media (max-width: 600px) {
            .leaderboard-header__top {
              display: block;
            }

            .leaderboard-header__title {
              margin-bottom: 24px;
              max-width: 90%;
            }

            .leaderboard-main__sub-title {
              margin-bottom: 66px;
            }

            .leaderboard-main__title {
              font-size: 24px;
              line-height: 32px;
            }

            .leaderboard-main__logo {
              width: 32px;
              height: 32px;
            }

            .leaderboard-header__sub-title {
              font-size: 14px;
            }
          }
        `}
      </style>

      <div className="leaderboard-header">
        <div className="leaderboard-header__top">
          <h1 className="leaderboard-header__title">
            {/* <img src="/static/images/partner/hipr-color-tr.png" className="leaderboard-main__logo" /> */}
            {contest?.title}
          </h1>
          {contest?.isShowCountdown ? (
            <Countdown endTime={contest?.endDate} title={`${contest?.title} ending in`} />
          ) : (
            ''
          )}
        </div>
        <div className="leaderboard-header__bottom">
          <p className="leaderboard-header__sub-title">{contest?.description}</p>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;

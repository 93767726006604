import React, { Fragment } from 'react';
import { formatCurrency } from '../../../lib/currency-utils';

const LeaderBoardCard = ({ order, collective, small }) => {
  return (
    <Fragment>
      <style jsx>{`
        .leaderboard-card {
          padding: 20px 20px 10px;
          background: #ffffff;
          border-radius: 16px;
          width: 364px;
          display: flex;
        }

        .leaderboard-card span {
          color: #333;
        }

        .leaderboard-card:nth-child(2) {
          margin: 0 12px;
        }

        .leaderboard-card__logo-box {
          width: 32px;
          min-width: 32px;
          height: 40px;
          margin-right: 24px;
        }

        .leaderboard-card__logo {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .leaderboard-card__info {
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        .leaderboard-card__header {
          display: flex;
        }

        .leaderboard-card__flag {
          width: 44px;
          height: 24px;
          object-fit: cover;
        }

        .leaderboard-card__organization-name {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          font-family: Roboto;
          margin-left: 10px;
          display: block;
        }

        .leaderboard-card__slogan {
          margin-top: 8px;
          font-family: Roboto;
          font-size: 14px;
          line-height: 24px;
          display: block;
        }

        .leaderboard-card__tag {
          font-family: Roboto;
          font-size: 14px;
          line-height: 24px;
          display: block;
          color: #3385ff !important;
          cursor: pointer;
          margin-bottom: 8px;
        }

        .leaderboard-card__footer {
          border-top: 1px solid #e1e4e6;
          display: flex;
          justify-content: space-between;
          padding-top: 8px;
          margin-top: auto;
        }

        .leaderboard-card__donate-title {
          color: #9ea2a6;
        }

        .leaderboard-card__donate-value {
          font-weight: 500;
        }

        .leaderboard-card--small {
          width: 318px;
        }

        .leaderboard-card--small .leaderboard-card__organization-name {
          font-size: 15px;
        }
      `}</style>

      <a
        className={`leaderboard-card ${small && 'leaderboard-card--small'}`}
        href={`/${collective?.slug}`}
        target="_blank"
      >
        <div className="leaderboard-card__logo-box">
          <img src={`/static/images/leaderboard/medal--${order}.png`} className="leaderboard-card__logo" />
        </div>

        <div className="leaderboard-card__info">
          <div className="leaderboard-card__header">
            <img src={collective?.host?.imageUrl} className="leaderboard-card__flag" />
            <span className="leaderboard-card__organization-name">{collective?.name}</span>
          </div>

          <span className="leaderboard-card__slogan"> {collective?.host?.name}</span>
          <span className="leaderboard-card__tag">#{collective?.tags?.[0]}</span>

          <div className="leaderboard-card__footer">
            <span className="leaderboard-card__donate-title"></span>
            <span className="leaderboard-card__donate-value">{formatCurrency(collective?.totalDonationReceived)}</span>
          </div>
        </div>
      </a>
    </Fragment>
  );
};

export default LeaderBoardCard;
